@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap');
* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100vh;
    font-family: 'Poppins';
    background: rgb(5, 5, 5);
}

body {
    overflow-x: hidden;
}

.intro {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    mix-blend-mode: difference;
}

li {
    color: white;
}

.career-title {
    color: white;
    font-size: 20px;
    margin-top: 10px;
}

.second {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    mix-blend-mode: difference;
}

.flex-container {
    display: flex;
    flex-direction: column;
}

.container {
    height: 100vh;
    display: grid;
    place-items: center;
    text-align: center;
}

h1 {
    text-transform: uppercase;
    color: white;
    font-family: 'Press Start 2P', cursive;
    border: 10px solid white;
    padding: 10px;
}

h2 {
    color: whitek;
}

.card {
    display: grid;
    place-items: center;
    padding-left: 8em;
    padding-right: 8em;
    padding-top: 2em;
    padding-bottom: 2em;
    margin: 1em;
}

.prompt {
    place-items: left;
    text-align: start;
    font-family: 'Odibee Sans', cursive;
}

@media only screen and (max-width: 600px) {
    .card {
        padding: 2em;
    }
}

.grey {
    color: white;
    background-color: rgba(10, 10, 10, 0.9);
}

section {
    height: 100vh;
}

.blinking-cursor {
    width: 5px;
    color: #2E3D48;
    animation: 1s blink step-end infinite;
}

@keyframes blink {
    from,
    to {
        color: transparent;
        background-color: transparent;
    }
    50% {
        color: whitesmoke;
        background-color: whitesmoke;
    }
}